<template>
  <v-card
    ref="card"
    style="overflow-y: auto; height: 100%;"
    elevation="0"
  >
    <v-card-text>
      <h1 class="display-1 mt-6 mb-10">
        Category editor
      </h1>

      <h2
        v-if="!selectedType"
        class="headline"
      >
        Select an element type to edit categories for
      </h2>
      <v-select
        v-model="selectedType"
        label="Type"
        :items="types"
        :disabled="hasChanges"
      />
      <p
        v-if="hasChanges"
        class="subtitle-2"
      >
        Save or discard your current changes to edit another type
      </p>

      <v-row v-if="selectedType">
        <v-col
          cols="12"
          md="6"
        >
          <h2 class="headline mb-5 text-capitalize">
            {{ selectedType }} categories (click to edit)
          </h2>
          <v-simple-table class="elevation-2">
            <template v-slot:default>
              <thead>
                <tr>
                  <th>
                    Name
                  </th>
                  <th style="text-align: end">
                    CPD Version
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in categories"
                  :key="item.name"
                  style="cursor: pointer;"
                  :style="{ 'background': index === editingCategoryIndex ? '#eeeeee' : null }"
                  @click="handleEditCategory(index)"
                >
                  <td
                    style="border-left: 5px solid transparent;"
                    :style="{ 'border-left-color': index === editingCategoryIndex ? 'var(--v-shamrock-base)' : 'transparent' }"
                  >
                    <v-text-field
                      v-if="renamingCategoryIndex === index"
                      v-on-clickaway="() => toggleRenamingCategory(index)"
                      :value="item.name"
                      @blur="(e) => handleCategoryNameChange(e.target.value)"
                    />
                    <span v-else>{{ item.name }}</span>
                  </td>
                  <td style="text-align: end">
                    <span v-if="renamingCategoryIndex !== index">v{{ item.cpdVersion || 2 }}</span>
                  </td>
                  <td class="text-right">
                    <EditAndDeleteButtons
                      v-if="editingCategoryIndex === index"
                      :edit-text="renamingCategoryIndex === index ? 'Save' : 'Rename'"
                      hide-edit
                      @edit="toggleRenamingCategory(index)"
                      @delete="handleDeleteCategory(index)"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <v-text-field
                      v-model="newCategoryName"
                      label="New category"
                    />
                  </td>
                  <td>
                    <v-checkbox
                      v-model="isV3"
                      label="V3"
                    />
                  </td>
                  <td>
                    <v-btn
                      color="shamrock"
                      dark
                      :elevation="0"
                      @click="handleCreateCategory"
                    >
                      Create category
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <div
            v-if="editingCategoryIndex !== null"
          >
            <h2 class="headline mb-5 text-capitalize">
              {{ categories[editingCategoryIndex].name }}
            </h2>
            <v-simple-table class="elevation-2">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(subCategoryName, index) in categories[editingCategoryIndex].subCategories"
                    :key="subCategoryName"
                  >
                    <td>
                      <v-text-field
                        v-if="renamingSubCategoryIndex === index"
                        v-on-clickaway="() => toggleRenamingSubCategory(index)"
                        :value="subCategoryName"
                        @input="handleSubCategoryNameChange"
                      />
                      <span v-else>{{ subCategoryName }}</span>
                    </td>
                    <td class="text-right">
                      <EditAndDeleteButtons
                        :edit-text="renamingSubCategoryIndex === index ? 'Save' : 'Rename'"
                        hide-edit
                        @edit="toggleRenamingSubCategory(index)"
                        @delete="handleDeleteSubCategory(index)"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <v-text-field
                        v-model="newSubCategoryName"
                        label="New sub-category"
                      />
                    </td>
                    <td>
                      <v-btn
                        color="shamrock"
                        dark
                        :elevation="0"
                        @click="handleCreateSubCategory"
                      >
                        Create sub category
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </v-col>
      </v-row>

      <div class="d-flex">
        <v-spacer />
        <v-btn
          text
          color="shamrock"
          class="mx-3"
          @click="$router.push('/admin/templates')"
        >
          Discard changes
        </v-btn>
        <v-btn
          :elevation="0"
          color="shamrock white--text"
          :loading="isSubmitting"
          :disabled="!hasChanges"
          @click="handleSaveChanges"
        >
          Save changes
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import EditAndDeleteButtons from '@/components/tables/EditAndDeleteButtons.vue'
import { mixin as clickaway } from 'vue-clickaway2'
import { mapActions as mapGlobalActions } from 'vuex'
import { captureException } from '@sentry/vue'

export default {
  name: 'CategoryEditor',
  components: {
    EditAndDeleteButtons
  },
  mixins: [clickaway],
  data () {
    return {
      types: [],
      selectedType: null,
      renamingCategoryIndex: null,
      editingCategoryIndex: null,
      renamingSubCategoryIndex: null,
      isSubmitting: false,
      hasChanges: false,
      categories: [],
      newCategoryName: '',
      isV3: true,
      newSubCategoryName: ''
    }
  },
  watch: {
    async selectedType () {
      this.refreshCategoriesForSelectedType()
    }
  },
  async created () {
    const res = await this.$axios.get(`/element_categories/types`)
    this.types = res.data
  },
  methods: {
    ...mapGlobalActions(['showSnackbar']),
    async refreshCategoriesForSelectedType () {
      const res = await this.$axios.get(`/element_categories`, { params: { type: this.selectedType } })
      this.categories = res.data
    },
    toggleRenamingCategory (categoryIndex) {
      if (this.renamingCategoryIndex === categoryIndex) {
        this.renamingCategoryIndex = null
      } else {
        this.renamingCategoryIndex = categoryIndex
      }
    },
    handleEditCategory (categoryIndex) {
      this.editingCategoryIndex = categoryIndex
    },
    handleCategoryNameChange (newName) {
      this.categories[this.renamingCategoryIndex].name = newName
      this.hasChanges = true
    },
    async handleDeleteCategory (categoryIndex) {
      const categoryName = this.categories[categoryIndex].name
      const { data: templatesInCategory } = await this.$axios.get('/templates/query', {
        params: {
          category: categoryName
        }
      })
      if (templatesInCategory.length > 0) {
        this.showSnackbar({
          color: 'warning',
          text: `Cannot delete "${categoryName}" as it has active templates:\n ${templatesInCategory.map((x) => `"${x.name}"`).join(', ')}`,
          timeout: 8000
        })
      } else {
        this.editingCategoryIndex = null
        this.categories.splice(categoryIndex, 1)
        this.hasChanges = true
      }
    },
    toggleRenamingSubCategory (subCategoryIndex) {
      if (this.renamingSubCategoryIndex === subCategoryIndex) {
        this.renamingSubCategoryIndex = null
      } else {
        this.renamingSubCategoryIndex = subCategoryIndex
      }
    },
    handleSubCategoryNameChange (newName) {
      this.categories[this.editingCategoryIndex].subCategories[this.renamingSubCategoryIndex] = newName
      this.hasChanges = true
    },
    handleCreateCategory () {
      if (!this.newCategoryName) return false
      this.categories.push({
        cpdVersion: this.isV3 ? 3 : 2,
        type: this.selectedType,
        name: this.newCategoryName,
        subCategories: [],
        showSuggestions: true
      })
      this.newCategoryName = ''
      this.hasChanges = true
    },
    handleCreateSubCategory () {
      this.categories[this.editingCategoryIndex].subCategories.push(this.newSubCategoryName)
      this.newSubCategoryName = ''
      this.hasChanges = true
    },
    async handleDeleteSubCategory (subCategoryIndex) {
      const subCategoryName = this.categories[this.editingCategoryIndex].subCategories[subCategoryIndex]
      const { data: templatesInSubCategory } = await this.$axios.get('/templates/query', {
        params: {
          suggestionsCategory: subCategoryName
        }
      })
      if (templatesInSubCategory.length > 0) {
        this.showSnackbar({
          color: 'warning',
          text: `Cannot delete "${subCategoryName}" as it has active templates:\n ${templatesInSubCategory.map((x) => `"${x.name}"`).join(', ')}`,
          timeout: 8000
        })
      } else {
        this.categories[this.editingCategoryIndex].subCategories.splice(subCategoryIndex, 1)
        this.hasChanges = true
      }
    },
    async handleSaveChanges () {
      this.isSubmitting = true
      try {
        await this.$axios.put(`/element_categories/${this.selectedType}`, this.categories)
        this.showSnackbar(`Categories updated for ${this.selectedType}`)
        this.selectedType = null
        this.hasChanges = false
      } catch (err) {
        this.showSnackbar({ color: 'error', text: 'Error updating categories.' })
        captureException(err)
      } finally {
        this.refreshCategoriesForSelectedType()
        this.isSubmitting = false
      }
    }
  }
}

</script>
